import useGlobalState from "@hooks/useGlobalState";
import { Button } from "@styles/buttons.styled";
import { media } from "@styles/styleUtils";
import styled from "styled-components";
import { AuthH1 } from "./SignIn";
import PlayDashboardNav from "@components/dashboard/PlayDashboardNav";

const Container = styled.div`
  width: 100%;
  max-width: 346px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.svp} {
    padding: 0 8px;
  }
`;

export const Heading = styled(AuthH1)`
  margin: 16px;
`;

export const Description = styled.p`
  max-width: 299px;
  color: var(--color_lvl_-1);
  font-weight: 325;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 24px;
`;

export default function ResetPasswordSuccess() {
  const [, dispatch] = useGlobalState();

  return (
    <>
      <PlayDashboardNav isLoggedIn={false} />
      <Container>
        <Heading>Success</Heading>
        <Description>
          Your password has been successfully reset. You may now use your new password to sign in.
        </Description>
        <Button
          size="md"
          $primary
          style={{ width: "100%" }}
          onClick={() =>
            dispatch((state) => {
              state.currentAuthScreen = "SIGN_IN";
            })
          }
        >
          Sign In
        </Button>
      </Container>
    </>
  );
}
