import useDalton from "@hooks/useDalton";
import useGlobalState, { useAuthView } from "@hooks/useGlobalState";
import { Button } from "@styles/buttons.styled";
import { Input, InputErrorText } from "@styles/inputs.styled";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { AuthBottomLink, AuthH1 } from "./SignIn";
import Arkose from "@components/arkose/Arkose";
import { useRouter } from "next/router";
import PlayDashboardNav from "@components/dashboard/PlayDashboardNav";

const MagicLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 40px;

  & p {
    max-width: 251px;
    margin-bottom: 24px;
    color: var(--color_lvl_-1);
  }
  & input {
    margin-bottom: 24px;
  }

  & button {
    margin-bottom: 13px;
    width: 100%;
  }

  & .success-copy {
    max-width: 330px;
  }

  & .mailbox-graphic {
    width: 400px;
    max-width: 85%;
    height: 270px;
    position: relative;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
`;

export default function MagicLink() {
  const [{ dalton, magicLinkCompleted, isIframe }] = useGlobalState();
  const { setAuthView } = useAuthView();
  const { daltonRequestMagicLink, daltonRequestPasswordToken, ARKOSE_REGISTRATION_PW_RESET_KEY } = useDalton();
  const [arkoseToken, setArkoseToken] = useState();
  const [arkoseComplete, setArkoseComplete] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [formAction, setFormAction] = useState("");
  const router = useRouter();

  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // const { getCookie } = useCookie();
  const wipEmail = (localStorage.getItem("authAttemptViaKey") || dalton.profile?.email) ?? "";
  const initialEmail = isValidEmail.test(wipEmail) ? wipEmail : "";

  const formik = useFormik({
    initialStatus: "idle",
    initialValues: {
      email: initialEmail.trim(),
      formAction: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: ({ email, formAction }) => {
      setApiErrorMessage("");
      setEmailValue(email);
      setFormAction(formAction);
    },
    validate: (values) => {
      const errors: Partial<typeof values> = {};
      if (!values.email || !isValidEmail.test(values.email.trim())) {
        errors.email = "Please provide a valid email address.";
        return errors;
      }
    },
  });

  function handleResetClick() {
    // set form value on Formik
    formik.setFieldValue("formAction", "reset-password");
    formik.submitForm();
  }
  function handleMagicLinkClick() {
    // set form value on Formik
    formik.setFieldValue("formAction", "magic-link");
    formik.submitForm();
  }

  const onSubmit = (email, arkoseToken) => {
    const authRequest = formAction === "reset-password" ? daltonRequestPasswordToken : daltonRequestMagicLink;

    return authRequest(email.trim(), arkoseToken)
      .then(() => {
        router.replace(`/${isIframe ? "?redirect=iframe" : ""}`, undefined, { shallow: true }).then(() => {
          formik.setStatus("complete");
        });
      })
      .catch((e) => {
        formik.setSubmitting(false);
        formik.setStatus("idle");
        setApiErrorMessage(`${e}`);
      })
      .finally(() => {
        setArkoseComplete(false);
      });
  };

  // if arkose is completed finish resetting the password
  useEffect(() => {
    if (arkoseComplete) {
      onSubmit(emailValue, arkoseToken);
    }
  }, [arkoseComplete]);

  useEffect(() => {
    if (magicLinkCompleted) {
      formik.setStatus("complete");
    }
  }, [magicLinkCompleted]);
  return (
    <>
      <PlayDashboardNav isLoggedIn={false} />
      <MagicLinkContainer>
        {formik.status === "complete" ? (
          <>
            <AuthH1>Email Sent</AuthH1>
            <p className="success-copy">Success! Check your inbox for instructions on how to get back in the game.</p>
          </>
        ) : (
          <>
            <AuthH1>Trouble Signing In?</AuthH1>
            <p>Enter your email for instructions on how to get back into the game.</p>
            <Form id="ncaa-play-trouble-sign-in" onSubmit={formik.handleSubmit}>
              <Input
                id="email"
                name="email"
                type="text"
                placeholder="Enter email"
                aria-label="Enter email"
                autoComplete="username"
                className={formik.errors.email && "error"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <InputErrorText
                style={{ top: "-19px" }}
                $shouldShow={Boolean(apiErrorMessage) || (formik.errors.email && formik.touched.email)}
              >
                {formik.errors.email ? formik.errors.email : apiErrorMessage}
              </InputErrorText>

              <Button size="md" id="arkose-reset-password" type="submit" onClick={handleResetClick} $primary>
                Reset Password
              </Button>
              <Button size="md" id="arkose-reset-password" type="submit" onClick={handleMagicLinkClick} $secondary>
                Send Magic Link
              </Button>
            </Form>
          </>
        )}
        <AuthBottomLink>
          <a
            onClick={() => {
              router.replace(`/${isIframe ? "?redirect=iframe" : ""}`, undefined, { shallow: true }).then(() => {
                setAuthView("SIGN_IN");
              });
            }}
          >
            Or return to sign in
          </a>
        </AuthBottomLink>
      </MagicLinkContainer>
      <Arkose
        publicKey={ARKOSE_REGISTRATION_PW_RESET_KEY}
        setArkoseToken={setArkoseToken}
        setArkoseComplete={setArkoseComplete}
        selector={"#arkose-reset-password"}
      />
    </>
  );
}
