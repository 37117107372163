import SocialLogin from "@components/forms/SocialLogin";
import Close from "@components/icons/Close.svg";
import SwitchComponent from "@components/SwitchComponent";
import useAnalytics from "@hooks/useAnalytics";
import useDalton from "@hooks/useDalton";
import useGlobalState, { useAuthView } from "@hooks/useGlobalState";
import { Button } from "@styles/buttons.styled";
import { FormErrorText, Input, InputErrorText, InputRow, PwInput, StyledVisibilityToggle } from "@styles/inputs.styled";
import { colors } from "@styles/styleUtils";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { AuthH1, AuthScreenContainer, AuthViewHeader } from "./SignIn";
import useBcgGame from "@hooks/bcg/useBcgGame";
import Arkose from "@components/arkose/Arkose";
import { useRouter } from "next/router";
import PrizingRulesIframe from "@components/Modal/PrizingRulesIframe";
import useJavaScriptBridge from "@hooks/useJavaScriptBridge";

const CloseIcon = styled(Close)`
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  & button[type="submit"] {
    margin-bottom: 0.8rem;
  }
  & .zip {
    flex: 0 1 120px;
  }
  & .newsletter-opt-in,
  & .prizing-opt-in {
    margin: 8px 0 24px;
    align-items: center;

    label {
      margin-left: 0;
      max-width: 230px;
    }
  }
  & .opt-in-toggle-input {
    display: flex;
    justify-content: right;
    margin-bottom: 12px;
    align-items: start;

    &.hide-toggle {
      display: none;
    }

    label {
      margin-right: auto;
      max-width: 240px;

      a {
        color: #009cde;
        text-decoration: none;
        user-select: text;

        &:focus-visible {
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }
  }

  [role="checkbox"] {
    margin-left: 5px;
  }
  label {
    margin-left: 8px;
    max-width: 75%;
    font-size: 1.4rem;
    line-height: 1.29;
  }
  & .terms-copy {
    padding: 0 4px;
    font-size: 1.4rem;
    line-height: 1.29;
    text-align: center;

    a {
      color: ${colors.bcg_accent_1};
      text-decoration: none;
      user-select: text;

      &:focus-visible {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  }
  & .signInPromp {
    & a {
      text-decoration: underline;
      text-underline-offset: 1px;
      color: ${colors.bcg_accent_1};
    }
  }
  & .inputDescription {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.14;
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: opacity 0.3s ease 0.1s, height 0.3s ease;
    &.show {
      visibility: visible;
      opacity: 1;
      height: 1.6rem;
      position: relative;
      top: -0.8rem;
    }
  }
`;

export default function SignUp() {
  const [state, dispatch] = useGlobalState();
  const [passwordVisibilityPressed, setPasswordVisibilityPressed] = useState(false);
  const [confirmPwVisibilityPressed, setConfirmPwVisibilityPressed] = useState(false);
  const [formValues, setFormValues] = useState<{
    email: string;
    password: string;
    confirmPassword: string;
    subscribed: boolean;
    prizing: any;
  }>();
  const [arkoseToken, setArkoseToken] = useState();
  const [arkoseComplete, setArkoseComplete] = useState(false);
  const [showSignInPrompt, setShowSignInPrompt] = useState<boolean>(false);
  const [showPwDescription, setShowPwDescription] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const { setAuthView } = useAuthView();
  const { signUp, ARKOSE_REGISTRATION_PW_RESET_KEY } = useDalton();
  const { pushEventData } = useAnalytics();
  const { data: game } = useBcgGame();
  const router = useRouter();
  const countryCode = Cookies.get("countryCode");
  const isCASignUp = countryCode === "CA" || !countryCode;
  const isOutsideUsSignUp = countryCode !== "US" || !countryCode;
  const [showPrizingModal, setShowPrizingModal] = useState(false);
  const { fireAction } = useJavaScriptBridge();

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      password: "",
      confirmPassword: "",
      subscribed: false,
      prizing: null,
    },
    onSubmit: (values) => {
      setApiErrorMessage("");
      if (apiErrorMessage) setApiErrorMessage("");
      if (showSignInPrompt) setShowSignInPrompt(false);
      setFormValues(values);
    },
    validateOnBlur: true,
    validateOnChange: false,
    validate: (values) => {
      const errors: Partial<typeof values> = {};
      if (!values.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email.trim())) {
        errors.email = "Please provide a valid email address.";
        return errors;
      }
      if (!values.firstName) {
        errors.firstName = "Name is required.";
        return errors;
      }
      if (!values.password) {
        errors.password = "Password is required.";
        return errors;
      }
      if (values.password.length < 8) {
        errors.password = "Password must contain at least 8 characters.";
        return errors;
      }
      if (values.password.trim() !== values.confirmPassword.trim()) {
        errors.confirmPassword = "Passwords do not match.";
        return errors;
      }
    },
  });

  const onSubmit = (values, arkoseToken) => {
    return signUp({
      email: values?.email.trim(),
      firstName: values?.firstName.trim(),
      password: values?.password.trim(),
      confirmPassword: values?.confirmPassword.trim(),
      subscribed: values?.subscribed,
      prizing: isOutsideUsSignUp ? null : values?.prizing,
      arkoseToken: arkoseToken,
    })
      .then(() => {
        pushEventData({
          eventName: "register_challenge_complete",
          eventAction: "email",
          eventTarget: "register_complete",
          eventType: "play",
        });
        try {
          window.trackMetrics({
            type: "account-registration-complete",
            data: {},
          });
        } catch (e) {} // eslint-disable-line
      })
      .catch((e) => {
        formik.setSubmitting(false);

        // A 429 from daltonIdentity means a user is signing up with an email already associated with an account; we then present a link to SIGN_IN.
        if (e.status === 429) {
          return setShowSignInPrompt(true);
        }
        setApiErrorMessage(`${e}`);
      })
      .finally(() => {
        setArkoseComplete(false);
      });
  };

  const isIncomplete = Object.values(formik.values).some((val) => val === "");
  const hasEmailError = formik.errors.email && formik.touched.email;
  const hasFirstNameError = formik.errors.firstName && formik.touched.firstName;
  const hasPasswordError = formik.errors.password && formik.touched.password;
  const hasConfirmPasswordError = formik.errors.confirmPassword && formik.touched.confirmPassword;

  const isDisabled =
    isIncomplete || formik.isSubmitting || hasPasswordError || hasConfirmPasswordError || hasEmailError;

  useEffect(() => {
    if (!isCASignUp) {
      formik.setFieldValue("subscribed", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if arkose is completed finish registering the user
  useEffect(() => {
    if (arkoseComplete) {
      onSubmit(formValues, arkoseToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arkoseComplete]);

  const getRegisterCopy = () => {
    if (isCASignUp) {
      return (
        <>
          By registering, you agree with NCAA.com{" "}
          <a href="https://www.ncaa.com/tos?cid=bcg-play-landing" rel="noopener noreferrer" target="_blank">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="https://www.ncaa.com/privacy?cid=bcg-play-landing" rel="noopener noreferrer" target="_blank">
            Privacy Policy
          </a>
        </>
      );
    }
    return (
      <>
        By registering, you agree with NCAA.com{" "}
        <a href="https://www.ncaa.com/tos?cid=bcg-play-landing" rel="noopener noreferrer" target="_blank">
          Terms of Service
        </a>{" "}
        and{" "}
        <a href="https://www.ncaa.com/privacy?cid=bcg-play-landing" rel="noopener noreferrer" target="_blank">
          Privacy Policy
        </a>
        . You further acknowledge that we will send you news, ads, and offers from NCAA.com and affiliates.
      </>
    );
  };

  return (
    <AuthScreenContainer>
      <CloseIcon
        onClick={() => {
          // Reset entryType if auth is closed before signing up.
          dispatch((state) => {
            delete state.modal.options.entryType;
          });
          // if user x's out remove query params so that it removes them from the passwordless group join flow
          router.replace(`/${state.isIframe ? "?redirect=iframe" : ""}`, undefined, { shallow: true }).then(() => {
            setAuthView("AUTH_LANDING");
          });
        }}
      />
      <AuthViewHeader>
        <AuthH1>One Account. Every Game.</AuthH1>
        <a
          className="subtext"
          onClick={() =>
            pushEventData({
              eventName: "signin_start",
              eventAction: "signin_start",
              eventTarget: "start",
              eventType: "play",
            }).then(() => setAuthView("SIGN_IN"))
          }
        >
          HAVE AN ACCOUNT? SIGN IN
        </a>
      </AuthViewHeader>

      <Form id="ncaa-play-sign-up" onSubmit={formik.handleSubmit}>
        <InputRow>
          <Input
            id="email"
            name="email"
            type="text"
            placeholder="Enter email"
            aria-label="Enter email"
            autoComplete="username"
            className={((formik.errors.email && formik.touched.email) || showSignInPrompt) && "error"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (showSignInPrompt) {
                setShowSignInPrompt(false);
                setArkoseComplete(false);
              }
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
        </InputRow>
        <InputErrorText
          className="signInPromp"
          $shouldShow={(formik.errors.email && formik.touched.email) || showSignInPrompt}
          style={{ top: "-19px" }}
        >
          {showSignInPrompt ? (
            <>
              This email is already associated with an account. <a onClick={() => setAuthView("SIGN_IN")}>Sign In</a>
            </>
          ) : (
            formik.errors.email
          )}
        </InputErrorText>
        <InputRow>
          <Input
            name="firstName"
            type="text"
            placeholder="Enter username"
            aria-label="Enter username"
            className={`name${hasFirstNameError ? " error" : ""}`}
            autoComplete="given-name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
          />
        </InputRow>
        <InputRow>
          <PwInput
            type={passwordVisibilityPressed ? "text" : "password"}
            id="password"
            name="password"
            placeholder="Enter password"
            aria-label="Password"
            autoComplete="new-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            className={(hasPasswordError || hasConfirmPasswordError) && "error"}
            onFocus={() => setShowPwDescription(true)}
          />
          <StyledVisibilityToggle
            pressed={passwordVisibilityPressed}
            onPressedChange={setPasswordVisibilityPressed}
            className={(hasPasswordError || hasConfirmPasswordError) && "error"}
          />
        </InputRow>
        <div className={`inputDescription${showPwDescription ? " show" : ""}`}>
          Password must contain at least 8 characters.
        </div>
        <InputRow>
          <PwInput
            type={confirmPwVisibilityPressed ? "text" : "password"}
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Re-enter password"
            aria-label="Re-enter Password"
            autoComplete="off"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            className={formik.errors.confirmPassword && formik.touched.confirmPassword && "error"}
          />
          <StyledVisibilityToggle
            pressed={confirmPwVisibilityPressed}
            onPressedChange={setConfirmPwVisibilityPressed}
            className={formik.errors.confirmPassword && formik.touched.confirmPassword && "error"}
          />
        </InputRow>

        <InputRow className={`newsletter-opt-in opt-in-toggle-input ${!isCASignUp ? "hide-toggle" : ""}`}>
          <label>Please send me news, ads, and offers from NCAA.com and affiliates</label>
          <div className="switch-wrapper">
            <SwitchComponent
              name="subscribed"
              checked={formik.values.subscribed}
              onCheckedChange={(checked) => formik.setFieldValue("subscribed", checked)}
              data-opt-in-toggle
            />
          </div>
        </InputRow>
        {game?.signups_open && game?.show_prizing_modal && (
          <InputRow className={`prizing-opt-in opt-in-toggle-input ${isOutsideUsSignUp ? "hide-toggle" : ""}`}>
            <label>
              Please enter me in the 2024 Bracket Challenge Sweepstakes. No Purch. Nec. Open to 50 U.S./DC, 18+. I agree
              to the{" "}
              {state.isNative ? (
                <span
                  className="native-external-link"
                  onClick={() => {
                    // 2024-03-04: use `init_facebook_login` action as a workaround because
                    // the iOS team won't support the proper method for opening external URLs
                    fireAction({
                      action: "init_facebook_login",
                      extLinkUrl: `https://ncaa.com/play-prizing-rules`,
                      returnUrl: window.location.href,
                    });
                  }}
                >
                  Official Rules
                </span>
              ) : (
                <a href="https://ncaa.com/play-prizing-rules" rel="noopener noreferrer" target="_blank">
                  Official Rules
                </a>
              )}
              .
            </label>
            <div className="switch-wrapper">
              <SwitchComponent
                name="prizing"
                checked={formik.values.prizing}
                onCheckedChange={(checked) => formik.setFieldValue("prizing", checked)}
                data-prizing-toggle
              />
            </div>
          </InputRow>
        )}
        <InputRow className="register-text">
          <label>{getRegisterCopy()}</label>
        </InputRow>
        <FormErrorText
          style={{ textAlign: "center" }}
          $shouldShow={Boolean(apiErrorMessage) || hasFirstNameError || hasPasswordError || hasConfirmPasswordError}
        >
          {apiErrorMessage || formik.errors.firstName || formik.errors.password || formik.errors.confirmPassword}
        </FormErrorText>
        <Button id="arkose-registration" size="md" type="submit" $primary disabled={isDisabled}>
          {formik.isSubmitting ? "Loading..." : "Register"}
        </Button>
        <SocialLogin />
        <Arkose
          // runWhenEnforcementReady={Boolean(isSocialAuthAttempt)}
          publicKey={ARKOSE_REGISTRATION_PW_RESET_KEY}
          setArkoseToken={setArkoseToken}
          setArkoseComplete={setArkoseComplete}
          selector={"#arkose-registration"}
        />
      </Form>
      <PrizingRulesIframe
        isOpen={showPrizingModal}
        handleClose={() => {
          setShowPrizingModal(false);
        }}
      />
    </AuthScreenContainer>
  );
}
