import Image from "next/image";
import styled from "styled-components";
import { colors, media } from "../../styles/styleUtils";

const FailOverContainer = styled.div`
  margin: 29px auto 0 auto;
  text-align: center;
  width: 100%;
  max-width: 794px;
  ${media.svp} {
    padding: 0 8px;
  }
`;

const TemporarilyUnavailableCopy = styled.p`
  width: 100%;
  max-width: 794px;
  line-height: 1.41;
  font-size: 2.2rem;
  color: ${colors.live_error};
  text-align: center;
  margin: 115px 0 120px;

  ${media.mvp} {
    font-size: 1.6rem;
    margin: 60px 0 85px;
  }

  ${media.x("768px")} {
    font-size: 1.6rem;
    max-width: 375px;
    margin: 30px auto 130px;
  }
`;

const LandingGraphic = styled.div`
  position: relative;
  width: 438px;
  height: 310px;
  margin: 0 auto;

  ${media.svp} {
    width: 285px;
    height: 202px;
  }
`;

export default function AuthFailover() {
  return (
    <FailOverContainer>
      <TemporarilyUnavailableCopy>
        The Capital One® NCAA® Bracket Challenge is temporarily unavailable. <br />
        Check back soon to get in the game!
      </TemporarilyUnavailableCopy>
      <LandingGraphic>
        <Image src={"/assets/images/landing/landing-prop-3.svg"} alt="landing prop image" />
      </LandingGraphic>
    </FailOverContainer>
  );
}
