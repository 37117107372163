import { useEffect } from "react";

const Arkose = ({ publicKey, selector, setArkoseComplete, setArkoseToken, runWhenEnforcementReady = false }) => {
  // create arkose script and insert it in the Head of app
  function createArkoseScript() {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://wbd-api.arkoselabs.com/v2/${publicKey}/api.js`;
    script.setAttribute("data-callback", "setupEnforcement");
    script.async = true;
    script.defer = true;
    script.id = "arkose-script";

    document.head.append(script);
    // 🧹 clean up arkose script so theres no duplicate scripts
    return () => {
      const object = document.getElementById("arkose-script");
      object.remove();
    };
  }

  // Create the setup function that the Arkose Labs API will use to configure it's use
  // and the callbacks that it will trigger
  let myEnforcement = null;
  function setupEnforcement(enforcementObject) {
    myEnforcement = enforcementObject;
    myEnforcement.setConfig({
      selector: selector,
      mode: "modal",
      onReady: () => {
        if (runWhenEnforcementReady) {
          myEnforcement.run();
        }
      },
      onCompleted: (response) => {
        setArkoseComplete(response.completed);
        setArkoseToken(response.token);
      },
    });
  }

  // Make the setup function a global variable so that the Arkose Labs API can run it
  // once the API has loaded. The name of this variable MUST match the name of the setup
  // function defined in the ArkoseLabs component 'data-callback' attribute
  window.setupEnforcement = setupEnforcement;

  // We only want to have the API script tag created once
  useEffect(() => {
    return createArkoseScript();
    // eslint-disable-next-line
  }, []);

  return <div id="arkose-ec"></div>;
};

declare global {
  interface Window {
    setupEnforcement: any;
  }
}

export default Arkose;
