import React from "react";
import styled from "styled-components";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
// import settings from "../../settings";
// const {
//   APP_ENV,
//   ENV_NAMES: { PROD },
// } = settings;

const ContentContainer = styled.div`
  height: 90vh;
  text-align: center;
  padding: 16px 0 0 0;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;
type PrizingRulesIframeProps = {
  isOpen: boolean;
  handleClose: () => void;
};

export default function PrizingRulesIframe({ isOpen, handleClose }: PrizingRulesIframeProps) {
  return (
    <MMLModal handleClose={handleClose} isOpen={isOpen} removeContentPadding svpFullScreen>
      <ContentContainer>
        <iframe
          // src={`https://${APP_ENV === PROD ? "www" : "mml:turn3rsp0rt5!@qa"}.ncaa.com/webview/play-prizing-rules`}
          src={`https://mml:turn3rsp0rt5!@qa.ncaa.com/webview/play-prizing-rules`}
          sandbox=""
        ></iframe>
      </ContentContainer>
    </MMLModal>
  );
}
