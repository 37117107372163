import { Checkbox } from "@components/forms/Checkbox";
import useAnalytics from "@hooks/useAnalytics";
import useDalton from "@hooks/useDalton";
import useGlobalState from "@hooks/useGlobalState";
import { Button } from "@styles/buttons.styled";
import { FormErrorText, Input, InputRow } from "@styles/inputs.styled";
import { colors, media } from "@styles/styleUtils";
import { useFormik } from "formik";
import { useState } from "react";
import styled from "styled-components";

const UpdateProfileContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 346px;
`;

const UpdateProfileHeader = styled.div`
  p {
    text-align: center;
    margin: 16px 19px 24px;
  }
`;

const UpdateProfileH2 = styled.h2`
  margin-bottom: 1.6rem;
  font-size: 4.2rem;
  line-height: normal;
  letter-spacing: -0.05rem;
  text-align: center;
  text-transform: uppercase;
`;

const Form = styled.form`
  ${media.svp} {
    padding: 0 8px;
  }

  input {
    &.name {
      margin-right: 8px;
    }
  }

  [role="checkbox"] {
    margin-left: 5px;
  }

  label {
    font-size: 1.4rem;
    line-height: 1.29;
    max-width: 75%;
    margin-left: 8px;
  }

  .newsletter-opt-in {
    margin: 24px 0;
    align-items: center;
  }
`;

const UpdateProfileFooter = styled.div`
  button[type="submit"] {
    margin: 0 0 12px;
    max-width: 100%;
    min-width: 100%;
  }

  p {
    font-size: 1.4rem;
    text-align: center;

    a {
      margin-bottom: 16px;
      font-size: 1.4rem;
      line-height: 1.29;
      text-align: center;
      color: ${colors.bcg_accent_1};
      cursor: pointer;
    }
  }
`;

function UpdateProfile() {
  const [{ dalton, isNative, isIframe }] = useGlobalState();
  const { updateProfile } = useDalton();
  const { pushEventData } = useAnalytics();
  const isNotEmbeddedView = !(isNative || isIframe);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const { isSocialReg } = dalton;

  const formik = useFormik({
    initialValues: {
      firstName: dalton.profile?.firstName || "",
      email: dalton.profile?.email || "",
      subscribed: dalton.profile?.subscribed || false,
    },
    onSubmit: (values) => {
      setApiErrorMessage("");
      return updateProfile({
        firstName: values.firstName.trim(),
        subscribed: values.subscribed,
        currentProfile: !dalton?.profile?.email ? dalton?.profile : { ...dalton.profile, email: values.email },
        isSocialReg,
      })
        .then(() => {
          if (values.subscribed) {
            pushEventData({
              eventName: "marketing_optin",
              eventAction: "optin",
              eventTarget: "marketing_optin",
              eventType: "play",
            });
          }
        })
        .catch((e) => {
          setApiErrorMessage(`${e}`);
        });
    },
    validateOnBlur: true,
    validateOnChange: true,
    validate: (values) => {
      const errors: Partial<typeof values> = {};
      if (!values.firstName) {
        errors.firstName = "Name is required.";
        return errors;
      }
      if (!values.email && !dalton.profile.email) {
        errors.firstName = "Email is required.";
        return errors;
      }
    },
  });

  const isIncomplete = Object.values(formik.values).some((val) => val === "");
  const hasFirstNameError = formik.errors.firstName && formik.touched.firstName;
  const hasEmailErrors = formik.errors.email && formik.touched.email;
  const isDisabled = isIncomplete || formik.isSubmitting || Object.keys(formik.errors).length > 0;

  return (
    <UpdateProfileContainer>
      <UpdateProfileHeader>
        <UpdateProfileH2>Update Profile</UpdateProfileH2>
        <p>You must update your profile to continue</p>
      </UpdateProfileHeader>
      <Form id="ncaa-play-update-profile" onSubmit={formik.handleSubmit}>
        <InputRow>
          <Input
            name="firstName"
            type="text"
            placeholder="Enter your name"
            aria-label="Enter your first name"
            autoComplete="given-name"
            className={`name${hasFirstNameError ? " error" : ""}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
          />
        </InputRow>
        {!dalton.profile.email && (
          <InputRow>
            <Input
              name="email"
              type="text"
              placeholder="Enter your email"
              aria-label="Enter your email"
              autoComplete="email"
              className={`name${hasEmailErrors ? " error" : ""}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputRow>
        )}

        <InputRow className="newsletter-opt-in">
          <Checkbox
            name="subscribed"
            checked={formik.values.subscribed}
            onCheckedChange={(checked) => formik.setFieldValue("subscribed", checked)}
          />
          <label>Please send me news and offers from NCAA.com</label>
        </InputRow>
        <UpdateProfileFooter>
          <FormErrorText style={{ textAlign: "center" }} $shouldShow={Boolean(apiErrorMessage) || hasFirstNameError}>
            {apiErrorMessage || formik.errors.firstName}
          </FormErrorText>

          <Button type="submit" disabled={isDisabled}>
            Save
          </Button>

          {isNotEmbeddedView && (
            <p>
              By registering, you agree with NCAA.com <br />
              <a href="https://www.ncaa.com/tos?cid=bcg-play-landing" rel="noopener noreferrer" target="_blank">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="https://www.ncaa.com/privacy?cid=bcg-play-landing" rel="noopener noreferrer" target="_blank">
                Privacy Policy
              </a>
              .
            </p>
          )}
        </UpdateProfileFooter>
      </Form>
    </UpdateProfileContainer>
  );
}

export default UpdateProfile;
