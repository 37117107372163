import useDalton from "@hooks/useDalton";
import useGlobalState from "@hooks/useGlobalState";
import AppleSVG from "@icons/apple_icon_alt.svg";
import FacebookSVG from "@icons/facebook_icon_alt.svg";
import GoogleSVG from "@icons/google_icon_alt.svg";
import { colors, media } from "@styles/styleUtils";
import { useRouter } from "next/router";
import styled from "styled-components";

const SocialLoginContainer = styled.div`
  margin: 76px 0 14px;

  & .continue-with-subheading {
    color: ${colors["lvl_-5"]};
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.07;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    text-align: center;

    min-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${media.svp} {
      font-size: 1.2rem;
    }
  }

  & .button-area {
    margin: 34px 0 24px;
    display: flex;
    justify-content: center;

    button {
      flex: 0 0 5rem;
      height: 5rem;
      margin: 0 12.5px;
      padding: 0;
      border-radius: 50%;
      overflow: hidden;

      display: flex;
      justify-content: center;
      align-items: center;

      &:focus-visible {
        outline: 2px solid ${colors["lvl_-5"]};
        outline-offset: 4px;
      }

      &.social-icon {
        & svg {
          ${({ theme }) => (theme.mode === "light" ? "#17171d" : "#fff")};
          width: 40px;
          height: 40px;
        }
      }
    }

    ${media.svp} {
      margin: 14px 0 24px;
    }
  }

  ${media.svp} {
    margin: 14px 0 14px;
  }
`;

export default function SocialLogin() {
  const router = useRouter();
  const { query } = router;
  const [state, dispatch] = useGlobalState();
  const { socialAuthRequest } = useDalton();
  const { nativeDeviceType } = state;

  const handleAuthRequest = async (service: "apple" | "facebook" | "google") => {
    dispatch((state) => {
      state.isLoading = true;
    });

    await socialAuthRequest(service, query);
    await new Promise((resolve) => setTimeout(resolve, 6000));

    dispatch((state) => {
      state.isLoading = false;
    });
  };

  return (
    <SocialLoginContainer>
      <div className="continue-with-subheading">
        <span>or continue with</span>
      </div>
      <div className="button-area">
        <button className="apple social-icon" type="button" onClick={() => handleAuthRequest("apple")}>
          <AppleSVG />
        </button>
        <button className="facebook social-icon" type="button" onClick={() => handleAuthRequest("facebook")}>
          <FacebookSVG />
        </button>
        {!nativeDeviceType?.includes("fire") && (
          <button className="google social-icon" type="button" onClick={() => handleAuthRequest("google")}>
            <GoogleSVG />
          </button>
        )}
      </div>
    </SocialLoginContainer>
  );
}
