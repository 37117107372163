import SocialLogin from "@components/forms/SocialLogin";
import Close from "@components/icons/Close.svg";
import SwitchComponent from "@components/SwitchComponent";
import useAnalytics from "@hooks/useAnalytics";
import useCookie from "@hooks/useCookie";
import useDalton from "@hooks/useDalton";
import useGlobalState, { useAuthView } from "@hooks/useGlobalState";
import { Button, CircleLoader } from "@styles/buttons.styled";
import { FormErrorText, Input, InputErrorText, InputRow, PwInput, StyledVisibilityToggle } from "@styles/inputs.styled";
import { colors, media, pxToRem } from "@styles/styleUtils";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import useBcgGame from "@hooks/bcg/useBcgGame";
import Arkose from "@components/arkose/Arkose";
import useJavaScriptBridge from "@hooks/useJavaScriptBridge";

const CloseIcon = styled(Close)`
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
`;

export const AuthScreenContainer = styled.div`
  margin: 0 auto;
  margin-top: 40px;
  width: 100%;
  max-width: 346px;

  ${media.svp} {
    padding: 1rem;
  }
`;

export const AuthViewHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & .subtext {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 1.33;
    letter-spacing: 0.04rem;
    text-align: center;
    color: ${colors.bcg_accent_1};
    cursor: pointer;

    ${media.svp} {
      font-size: 1.2rem;
    }
  }
`;

export const AuthH1 = styled.h1`
  margin-bottom: 1.6rem;
  color: ${colors["lvl_-5"]};
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.05rem;
  text-align: center;
  text-transform: uppercase;

  ${media.svp} {
    margin-bottom: 0.5rem;
    font-size: 3.2rem;
  }
`;

export const AuthBottomLink = styled.div`
  text-align: center;

  & a {
    margin-bottom: 16px;
    font-size: 1.4rem;
    line-height: 1.33;
    letter-spacing: 0.04rem;
    text-align: center;
    color: ${colors.bcg_accent_1};
    cursor: pointer;

    ${media.svp} {
      font-size: 1.2rem;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  & .opt-in-toggle-input {
    display: flex;
    justify-content: right;
    margin-bottom: 12px;
    align-items: start;

    label {
      margin-right: auto;
      max-width: 240px;
      font-weight: 325;
      font-size: ${pxToRem(14)};
      line-height: 1.2;

      ${media.singleColumn} {
        font-size: ${pxToRem(12)};
        line-height: 1.3;
        letter-spacing: 0.4px;
        font-style: oblique;
      }
      a {
        color: #009cde;
        text-decoration: none;
        user-select: text;

        &:focus-visible {
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }
  }

  & button[type="submit"] {
    margin-bottom: 2.4rem;
    ${media.svp} {
      margin-bottom: 0.8rem;
    }
  }
`;

export default function SignIn() {
  const [state, dispatch] = useGlobalState();
  const router = useRouter();
  const [formValues, setFormValues] = useState<{ email: string; password: string; prizing: any }>();
  const [arkoseToken, setArkoseToken] = useState();
  const [arkoseComplete, setArkoseComplete] = useState(false);
  const { query } = router;
  const [visibilityPressed, setVisibilityPressed] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [passwordlessLoginStatus, setPasswordlessLoginStatus] = useState<"idle" | "loading" | "complete">("idle");
  const { setAuthView } = useAuthView();
  const {
    signIn,
    socialAuthHandleSubmit,
    magicLinkHandleSubmit,
    checkIdentityTypes,
    socialSignInWithoutDaltonEmail,
    ARKOSE_REGISTRATION_PW_RESET_KEY,
    ARKOSE_LOGIN_KEY,
  } = useDalton();
  const { pushEventData } = useAnalytics();
  const [statusCode, setStatusCode] = useState(null);
  const [userIdentities, setUserIdentities] = useState(null);
  const [publicKey, setPublicKey] = useState(null);
  const [needsEmailAddedToProfile, setNeedsEmailAddedToProfile] = useState(false);
  const { setCookie } = useCookie();
  const { fireAction } = useJavaScriptBridge();

  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const { data: game } = useBcgGame();

  const handleTroubleClick = () => {
    if (formik.values.email && isValidEmail.test(formik.values.email)) {
      setCookie("authAttemptViaKey", formik.values.email);
    }
    setAuthView("TROUBLE_MAGIC_LINK");
  };

  const countryCode = Cookies.get("countryCode");
  const isOutsideUsSignUp = countryCode !== "US" || !countryCode;

  const hashParams = useMemo(() => new URLSearchParams(window.location.hash.replace("#", "?")), []);
  const token = query.token || hashParams.get("token");
  const address = query.address || hashParams.get("address");
  const id_token = query.id_token || hashParams.get("id_token");
  const access_token = query.access_token || hashParams.get("access_token");

  const isSocialAuthAttempt = id_token || access_token;
  const isMagicLinkAttempt = token && address;
  const updatedQuery = { ...query };
  for (const [key, value] of hashParams) {
    updatedQuery[key] = value;
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      prizing: null,
    },
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      setApiErrorMessage("");
      setFormValues(values);
    },
    validate: (values) => {
      const errors: Partial<typeof values> = {};
      if (!values.email || !isValidEmail.test(values.email.trim())) {
        errors.email = "Please provide a valid email address.";
        return errors;
      }
    },
  });

  const onSubmit = (values, arkoseToken) => {
    return signIn(
      values.email.trim(),
      values.password.trim(),
      false,
      isOutsideUsSignUp ? null : values.prizing,
      arkoseToken,
    )
      .then(() => {
        pushEventData({
          eventName: "login_challenge_complete",
          eventAction: "email",
          eventTarget: "login_complete",
          eventType: "play",
        });
      })
      .catch((e) => {
        console.error(e);
        setApiErrorMessage(`${e}`);
        formik.setSubmitting(false);
      })
      .finally(() => {
        setArkoseComplete(false);
      });
  };

  const isIncomplete = Object.values(formik.values).some((val) => val === "");
  const isDisabled =
    isIncomplete ||
    formik.isSubmitting ||
    passwordlessLoginStatus === "loading" ||
    Object.keys(formik.errors).length > 0;

  useEffect(() => {
    const updatedQuery = { ...query };

    for (const [key, value] of hashParams) {
      updatedQuery[key] = value;
    }
    if (isMagicLinkAttempt && passwordlessLoginStatus === "idle") {
      // once arkose is completed run authRequest
      if (arkoseComplete) {
        setPasswordlessLoginStatus("loading");
        magicLinkHandleSubmit(updatedQuery, arkoseToken)
          .catch((e) => setApiErrorMessage(`${e}`))
          .finally(() => setPasswordlessLoginStatus("complete"));
      }
    }
  }, [
    query,
    magicLinkHandleSubmit,
    passwordlessLoginStatus,
    arkoseComplete,
    isMagicLinkAttempt,
    arkoseToken,
    hashParams,
  ]);

  useEffect(() => {
    const updatedQuery = { ...query };
    for (const [key, value] of hashParams) {
      updatedQuery[key] = value;
    }

    if (
      isSocialAuthAttempt &&
      passwordlessLoginStatus === "idle" &&
      statusCode === null &&
      userIdentities === null &&
      publicKey === null
    ) {
      // make a call to identitytypes with the social credential for every SOCIAL attempt
      if (statusCode === null) {
        checkIdentityTypes(updatedQuery)
          .then((res: any) => {
            setStatusCode(res?.responseOptions?.status);
            // re-run arkose to get login public key instead of register, Ricky plans to purge any accounts without an email prior to 2025 so this might not be needed afterwards.
            if (res?.responseOptions?.status === 424) {
              setPublicKey(ARKOSE_REGISTRATION_PW_RESET_KEY);
            } else {
              setPublicKey(ARKOSE_LOGIN_KEY);
              delete res.responseOptions;
              setUserIdentities(Object.values(res).map((type: any) => type?.identityType));
            }
          })
          .catch((e) => {
            setApiErrorMessage(`${e}`);
          });
      }
    }

    if (arkoseComplete && statusCode !== null && !needsEmailAddedToProfile) {
      socialAuthHandleSubmit(
        updatedQuery,
        arkoseToken,
        statusCode,
        userIdentities,
        setPublicKey,
        setArkoseComplete,
        setNeedsEmailAddedToProfile,
      );
    }
  }, [
    query,
    publicKey,
    arkoseComplete,
    passwordlessLoginStatus,
    isSocialAuthAttempt,
    arkoseToken,
    statusCode,
    userIdentities,
    needsEmailAddedToProfile,
  ]);

  // if a user needs an email added to dalton profile login and an add it
  useEffect(() => {
    if (needsEmailAddedToProfile && arkoseComplete) {
      socialSignInWithoutDaltonEmail(updatedQuery, arkoseToken);
    }
  }, [needsEmailAddedToProfile, arkoseComplete]);

  // if arkose is completed, finish logging in the user if not from socialAuth or magicLink
  useEffect(() => {
    if (arkoseComplete && !isSocialAuthAttempt && !isMagicLinkAttempt) {
      onSubmit(formValues, arkoseToken);
    }
  }, [arkoseComplete, isSocialAuthAttempt, isMagicLinkAttempt]);

  // if not social auth default arkose public key to login
  useEffect(() => {
    if (!isSocialAuthAttempt) {
      setPublicKey(ARKOSE_LOGIN_KEY);
    }
  }, [isSocialAuthAttempt]);

  return (
    <AuthScreenContainer>
      <CloseIcon
        onClick={() => {
          // Reset entryType if auth is closed before signing in.
          dispatch((state) => {
            delete state.modal.options.entryType;
          });
          // if user x's out remove query params so that it removes them from the passwordless group join flow
          router.replace(`/${state.isIframe ? "?redirect=iframe" : ""}`, undefined, { shallow: true }).then(() => {
            setAuthView("AUTH_LANDING");
          });
        }}
      />
      <AuthViewHeader>
        <AuthH1>One Account. Every Game.</AuthH1>
        <a className="subtext" onClick={() => setAuthView("SIGN_UP")}>
          NOT REGISTERED? CREATE ACCOUNT
        </a>
      </AuthViewHeader>

      <Form id="ncaa-play-sign-in" onSubmit={formik.handleSubmit}>
        <InputRow>
          <Input
            id="email"
            name="email"
            type="text"
            placeholder="Enter email"
            aria-label="Enter email"
            autoComplete="username"
            className={formik.errors.email && "error"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
        </InputRow>
        <InputErrorText $shouldShow={formik.errors.email && formik.touched.email} style={{ top: "-19px" }}>
          {formik.errors.email}
        </InputErrorText>

        <InputRow>
          <PwInput
            id="password"
            name="password"
            type={visibilityPressed ? "text" : "password"}
            placeholder="Enter password"
            aria-label="Enter password"
            autoComplete="current-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          <StyledVisibilityToggle pressed={visibilityPressed} onPressedChange={setVisibilityPressed} />
        </InputRow>

        {game?.signups_open && game?.show_prizing_modal && (
          <InputRow className={`prizing-opt-in opt-in-toggle-input ${isOutsideUsSignUp ? "hide-toggle" : ""}`}>
            <label>
              Please enter me in the 2024 Bracket Challenge Sweepstakes. No Purch. Nec. Open to 50 U.S./DC, 18+. I agree
              to the{" "}
              {state.isNative ? (
                <span
                  className="native-external-link"
                  onClick={() => {
                    // 2024-03-04: use `init_facebook_login` action as a workaround because
                    // the iOS team won't support the proper method for opening external URLs
                    fireAction({
                      action: "init_facebook_login",
                      extLinkUrl: `https://ncaa.com/play-prizing-rules`,
                      returnUrl: window.location.href,
                    });
                  }}
                >
                  Official Rules
                </span>
              ) : (
                <a href="https://ncaa.com/play-prizing-rules" rel="noopener noreferrer" target="_blank">
                  Official Rules
                </a>
              )}
              .
            </label>
            <div className="switch-wrapper">
              <SwitchComponent
                name="prizing"
                checked={formik.values.prizing}
                onCheckedChange={(checked) => formik.setFieldValue("prizing", checked)}
                data-prizing-toggle
              />
            </div>
          </InputRow>
        )}

        <FormErrorText style={{ textAlign: "center" }} $shouldShow={Boolean(apiErrorMessage)}>
          {apiErrorMessage}
        </FormErrorText>
        <Button
          size="md"
          type="submit"
          $primary
          disabled={isDisabled && passwordlessLoginStatus !== "loading"}
          id="arkose-sign-in"
        >
          {formik.isSubmitting ? "Loading..." : "Sign In"}
          {passwordlessLoginStatus === "loading" && <CircleLoader />}
        </Button>
        <AuthBottomLink>
          <a onClick={handleTroubleClick}>Trouble Signing In?</a>
        </AuthBottomLink>
        <SocialLogin />
      </Form>
      {publicKey === ARKOSE_LOGIN_KEY && (
        <Arkose
          publicKey={publicKey}
          setArkoseToken={setArkoseToken}
          setArkoseComplete={setArkoseComplete}
          selector={isSocialAuthAttempt ? "#arkose-social-login" : "#arkose-sign-in"}
          runWhenEnforcementReady={
            (Boolean(isSocialAuthAttempt) && statusCode != null) ||
            Boolean(isMagicLinkAttempt) ||
            needsEmailAddedToProfile
          }
        />
      )}
      {publicKey === ARKOSE_REGISTRATION_PW_RESET_KEY && (
        <Arkose
          publicKey={publicKey}
          setArkoseToken={setArkoseToken}
          setArkoseComplete={setArkoseComplete}
          selector={isSocialAuthAttempt ? "#arkose-social-login" : "#arkose-sign-in"}
          runWhenEnforcementReady={(Boolean(isSocialAuthAttempt) && statusCode != null) || Boolean(isMagicLinkAttempt)}
        />
      )}
    </AuthScreenContainer>
  );
}
