import AppleSVG from "@components/icons/social-apple-no-background.svg";
import GoogleSVG from "@components/icons/social-google.svg";
import useDalton from "@hooks/useDalton";
import useGlobalState from "@hooks/useGlobalState";
import FacebookSVG from "@icons/glyphs-bcg-fb-on.svg";
import { Button } from "@styles/buttons.styled";
import styled from "styled-components";
import { AuthH1 } from "./SignIn";
import Arkose from "@components/arkose/Arkose";
import { useEffect, useState } from "react";

const AuthMergeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 350px;
  margin-top: 56px;

  & p {
    margin: 16px auto 28px;
    padding: 0 20px;
  }

  & button {
    width: 100%;
    margin-bottom: 8px;
  }

  & svg {
    margin-right: 13px;
    height: 26px;
    width: 26px;
  }
`;

export default function AuthMerge() {
  const [state, dispatch] = useGlobalState();
  const { socialAuthRequest, daltonRequestMagicLink, ARKOSE_REGISTRATION_PW_RESET_KEY } = useDalton();
  const [arkoseToken, setArkoseToken] = useState(null);
  const [arkoseComplete, setArkoseComplete] = useState(false);

  useEffect(() => {
    if (arkoseComplete && state.dalton.wipEmail) {
      daltonRequestMagicLink(state.dalton.wipEmail.trim(), arkoseToken).then(() => {
        dispatch((state) => {
          state.currentAuthScreen = "TROUBLE_MAGIC_LINK";
          state.magicLinkCompleted = true;
        });
      });
    }
  }, [arkoseComplete, state.dalton.wipEmail, daltonRequestMagicLink, arkoseToken, dispatch]);

  return (
    <AuthMergeContainer>
      <AuthH1>Already a Member</AuthH1>
      <p>
        You have previously logged in with a different account associated with <em>{state.dalton.wipEmail}</em>. Connect
        with your existing account to get back in the game.
      </p>
      {state.dalton.identityType.includes("EMAIL") && <Button id="arkose-magic-link">Send Magic Link</Button>}
      {state.dalton.identityType.includes("APPLE") && (
        <Button $secondary onClick={() => socialAuthRequest("apple")}>
          <AppleSVG /> Continue With Apple
        </Button>
      )}
      {state.dalton.identityType.includes("GOOGLE") && (
        <Button $secondary onClick={() => socialAuthRequest("google")}>
          <GoogleSVG /> Continue With Google
        </Button>
      )}
      {/FACEBOOK/.test(state.dalton.identityType.toString()) && (
        <Button $secondary onClick={() => socialAuthRequest("facebook")}>
          <FacebookSVG /> Continue With FACEBOOK
        </Button>
      )}
      <Arkose
        publicKey={ARKOSE_REGISTRATION_PW_RESET_KEY}
        setArkoseToken={setArkoseToken}
        setArkoseComplete={setArkoseComplete}
        selector={"#arkose-magic-link"}
      />
    </AuthMergeContainer>
  );
}
