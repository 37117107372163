import useDalton from "@hooks/useDalton";
import { useAuthView } from "@hooks/useGlobalState";
import { Button } from "@styles/buttons.styled";
import { FormErrorText, InputRow, PwInput, StyledVisibilityToggle } from "@styles/inputs.styled";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { AuthBottomLink, AuthH1 } from "./SignIn";
import Arkose from "@components/arkose/Arkose";
import PlayDashboardNav from "@components/dashboard/PlayDashboardNav";

const PasswordResetSuccessContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  width: 100%;
  max-width: 346px;
`;

const Heading = styled(AuthH1)`
  margin: 48px 0 24px 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;
  width: 100%;

  & .inputDescription {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.14;
    text-align: start;
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: opacity 0.3s ease 0.1s, height 0.3s ease;
    &.show {
      visibility: visible;
      opacity: 1;
      height: 1.6rem;
      position: relative;
      top: -0.8rem;
    }
  }
`;

export default function ResetPassword() {
  const { query } = useRouter();
  const { setAuthView } = useAuthView();
  const { resetPassword, ARKOSE_REGISTRATION_PW_RESET_KEY } = useDalton();
  const [arkoseToken, setArkoseToken] = useState();
  const [arkoseComplete, setArkoseComplete] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [pwVisibilityPressed, setPwVisibilityPressed] = useState(false);
  const [reEnterPwVisibilityPressed, setReEnterPwVisibilityPressed] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [showPwDescription, setShowPwDescription] = useState(false);

  const onSubmit = (password, arkoseToken) => {
    return resetPassword(resetToken, password.trim(), arkoseToken)
      .then(() => setAuthView("RESET_PW_SUCCESS"))
      .catch((e) => setApiErrorMessage(`${e}`));
  };

  const resetToken = query.resetToken?.toString() ?? "";
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: ({ password }) => {
      setApiErrorMessage("");
      setPasswordValue(password);
    },
    validate: (values) => {
      const errors: Partial<typeof values> = {};
      if (!values.password) {
        errors.password = "Password is required.";
        return errors;
      }
      if (values.password.length < 8) {
        errors.password = "Password must contain at least 8 characters.";
        return errors;
      }
      if (values.password.trim() !== values.confirmPassword.trim()) {
        errors.confirmPassword = "Passwords do not match.";
        return errors;
      }
    },
  });
  // if arkose is completed finish resetting the password
  useEffect(() => {
    if (arkoseComplete) {
      onSubmit(passwordValue, arkoseToken);
    }
  }, [arkoseComplete]);

  const isIncomplete = Object.values(formik.values).some((val) => val === "");
  const hasPasswordError = formik.errors.password && formik.touched.password;
  const hasConfirmPasswordError = formik.errors.confirmPassword && formik.touched.confirmPassword;

  return (
    <>
      <PlayDashboardNav isLoggedIn={false} />
      <PasswordResetSuccessContainer>
        <Heading>Reset Password</Heading>
        <Form id="ncaa-play-reset-password" onSubmit={formik.handleSubmit}>
          <input hidden type="text" autoComplete="username" />
          <InputRow>
            <PwInput
              type={pwVisibilityPressed ? "text" : "password"}
              placeholder="Enter new password"
              aria-label="Enter new password"
              id="password"
              name="password"
              autoComplete="new-password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              className={(hasPasswordError || hasConfirmPasswordError) && "error"}
              onFocus={() => setShowPwDescription(true)}
            />
            <StyledVisibilityToggle
              pressed={pwVisibilityPressed}
              onPressedChange={setPwVisibilityPressed}
              className={(hasPasswordError || hasConfirmPasswordError) && "error"}
            />
          </InputRow>
          <div className={`inputDescription${showPwDescription ? " show" : ""}`}>
            Password must contain at least 8 characters.
          </div>
          <InputRow>
            <PwInput
              type={reEnterPwVisibilityPressed ? "text" : "password"}
              placeholder="Re-enter new password"
              aria-label="Re-enter new password"
              id="confirmPassword"
              name="confirmPassword"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              className={hasConfirmPasswordError && "error"}
            />
            <StyledVisibilityToggle
              pressed={reEnterPwVisibilityPressed}
              onPressedChange={setReEnterPwVisibilityPressed}
              className={hasConfirmPasswordError && "error"}
            />
          </InputRow>
          <FormErrorText
            style={{ textAlign: "center" }}
            $shouldShow={Boolean(apiErrorMessage) || hasPasswordError || hasConfirmPasswordError}
          >
            {apiErrorMessage || formik.errors.password || formik.errors.confirmPassword}
          </FormErrorText>
          <Button
            size="md"
            $primary
            id="arkose-password-reset"
            type="submit"
            disabled={isIncomplete || formik.isSubmitting}
          >
            Submit
          </Button>
          <Arkose
            publicKey={ARKOSE_REGISTRATION_PW_RESET_KEY}
            setArkoseToken={setArkoseToken}
            setArkoseComplete={setArkoseComplete}
            selector={"#arkose-password-reset"}
          />
        </Form>
        <AuthBottomLink>
          <a onClick={() => setAuthView("SIGN_IN")}>Or return to sign in</a>
        </AuthBottomLink>
      </PasswordResetSuccessContainer>
    </>
  );
}
