import AuthLanding from "@components/auth-views/AuthLanding";
import AuthMerge from "@components/auth-views/AuthMerge";
import AuthFailover from "@components/auth-views/FailOver";
import MagicLink from "@components/auth-views/MagicLink";
import ResetPassword from "@components/auth-views/ResetPassword";
import ResetPasswordSuccess from "@components/auth-views/ResetPasswordSuccess";
import SignIn from "@components/auth-views/SignIn";
import SignUp from "@components/auth-views/SignUp";
import UpdateProfile from "@components/auth-views/UpdateProfile";
import useBcgGame, { BcgGame } from "@hooks/bcg/useBcgGame";
import useAds from "@hooks/useAds";
import useAnalytics from "@hooks/useAnalytics";
import useGlobalState, { useAuthView } from "@hooks/useGlobalState";
import type { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { QueryClient } from "@tanstack/react-query";
import { getBcgGame, getIsLoggedIn, getQueryParamString, getServerSideCookie } from "src/lib/server-side-helpers";
import settings from "src/settings";
import { TAuthSteps } from "src/types/auth";
import AuthLandingLayout from "@components/layouts/AuthLandingLayout";

const {
  APP_ENV,
  ENV_NAMES: { DEV },
} = settings;

interface PageProps {
  initAuthView?: string;
  isLoggedIn: boolean;
}

export default function Index({ initAuthView, isLoggedIn }: PageProps) {
  const [authViewOverride, setAuthViewOverride] = useState(initAuthView);
  const [{ currentAuthScreen, isNative, isIframe }] = useGlobalState();
  const { setAuthView } = useAuthView();
  const { updateDataLayer } = useAnalytics();
  const { firePixel } = useAds();
  const { data: game } = useBcgGame();
  const dalton_failover_enabled = game?.dalton_fallback_enabled || false;

  const authViews = useMemo<AuthComponentMap>(
    () => ({
      SIGN_UP: <SignUp />,
      SIGN_IN: <SignIn />,
      AUTH_LANDING: <AuthLanding isLoggedIn={isLoggedIn} />,
      AUTH_MERGE: <AuthMerge />,
      TROUBLE_MAGIC_LINK: <MagicLink />,
      RESET_PW: <ResetPassword />,
      RESET_PW_SUCCESS: <ResetPasswordSuccess />,
      UPDATE_PROFILE: <UpdateProfile />,
      FAILOVER: <AuthFailover />,
    }),
    [isLoggedIn],
  );

  const isAuthView = useCallback(
    (queryValue: string): queryValue is TAuthSteps => Object.keys(authViews).includes(queryValue),
    [authViews],
  );

  const selectedAuthView = authViewOverride ?? currentAuthScreen;
  const authComponent = dalton_failover_enabled ? <AuthFailover /> : authViews[selectedAuthView];
  const { query } = useRouter();

  const trackScreen = useCallback(
    (screen: TAuthSteps) => {
      const pageNames = {
        AUTH_LANDING: "registration landing",
        SIGN_UP: "play create account",
        SIGN_IN: "play sign in",
        UPDATE_PROFILE: "play update profile",
        TROUBLE_MAGIC_LINK: "play trouble signing in",
        RESET_PW: "play password email sent",
        RESET_PW_SUCCESS: "play reset password success",
      };
      const urls = {
        AUTH_LANDING: "ncaa:watchmml:play:registration",
        SIGN_UP: "ncaa:watchmml:play:registration:create_account",
        SIGN_IN: "ncaa:watchmml:play:registration:sign_in",
        UPDATE_PROFILE: "ncaa:watchmml:play:registration:update_profile",
        TROUBLE_MAGIC_LINK: "ncaa:watchmml:play:registration:trouble_signing_in",
        RESET_PW: "ncaa:watchmml:play:registration:password_sent",
        RESET_PW_SUCCESS: "ncaa:watchmml:play:registration:password_success",
      };

      if (pageNames[screen]) {
        const data = {
          page: {
            pageInfo: {
              pageName: pageNames[screen],
              mmlUrl: urls[screen],
              section: "play",
              subsection: "play:registration",
            },
          },
        };
        updateDataLayer(data, screen !== "AUTH_LANDING");
      }
      if (screen === "SIGN_UP") {
        firePixel("signup", "slc");
        firePixel("signup", "bcg");
        if (!(isNative || isIframe)) firePixel("signup", "wbcg");
      }
      if (screen === "SIGN_IN") {
        firePixel("login", "slc");
        firePixel("login", "bcg");
        if (!(isNative || isIframe)) firePixel("login", "wbcg");
      }
      if (screen === "AUTH_LANDING") {
        firePixel("landingpage", "slc");
        firePixel("landingpage", "bcg");
        if (!(isNative || isIframe)) firePixel("landingpage", "wbcg");

        firePixel("onboarding", "slc");
        firePixel("onboarding", "bcg");
        if (!(isNative || isIframe)) firePixel("onboarding", "wbcg");
      }
    },
    [updateDataLayer, firePixel, isNative, isIframe],
  );

  useEffect(() => {
    const hashParams = new URLSearchParams(window.location.hash.replace("#", "?"));

    const token = query.token || hashParams.get("token");
    const address = query.address || hashParams.get("address");
    const id_token = query.id_token || hashParams.get("id_token");
    const access_token = query.access_token || hashParams.get("access_token");

    const hasSocialAuthParams = id_token || access_token;
    const hasMagicLinkParams = token && address;

    if (hasSocialAuthParams || hasMagicLinkParams) {
      setAuthViewOverride("SIGN_IN");
    }
  }, [query]);

  /**
   * Effect for the initial auth screen init logic where we set and then clear the auth view based on an override value.
   */
  useEffect(() => {
    if (authViewOverride) {
      if (isAuthView(authViewOverride)) {
        setAuthView(authViewOverride);
      }
      setAuthViewOverride(null);
    }
  }, [authViewOverride, currentAuthScreen, setAuthView, isAuthView]);

  useEffect(() => {
    trackScreen(currentAuthScreen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAuthScreen]);

  return authComponent;
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const queryClient = new QueryClient();
  const query = context.query;
  const isLoggedIn = getIsLoggedIn(context);
  const queryParams = getQueryParamString(context);

  // Adds dev environment support for ?auth=TAuthStep params.
  // @example http://play-development.ncaa.com:8000/?auth=SIGN_IN
  if (APP_ENV === DEV && query.auth !== undefined) {
    return {
      props: {
        initAuthView: query.auth,
        isLoggedIn,
      },
    };
  }

  if (isLoggedIn) {
    return {
      redirect: {
        permanent: false,
        destination: `/dashboard${queryParams.length ? `?${queryParams}` : ""}`,
      },
    };
  }

  await getServerSideCookie(context);

  // Add production support for a ?authenticate param to initialize on the SignUp view when signed out.
  // @example https://play.ncaa.com/?authenticate
  if (query.authenticate !== undefined || query.fromMbcgGroup !== undefined || query.fromWbcgGroup != undefined) {
    return {
      props: {
        initAuthView: "SIGN_UP",
        isLoggedIn,
      },
    };
  }

  const hasResetPwToken = Boolean(query.resetToken);
  if (hasResetPwToken) {
    return {
      props: {
        initAuthView: "RESET_PW",
        isLoggedIn,
      },
    };
  }

  // prefetch useGame with SSR
  await queryClient.prefetchQuery({ queryKey: ["game", "mens-bcg"], queryFn: () => getBcgGame() });
  const game = queryClient.getQueryData<BcgGame>(["game", "mens-bcg"]);

  // Gate logic to redirect to marketing sign up page before games start
  if (game?.state === -3) {
    return {
      redirect: {
        permanent: false,
        destination: `/sign-up`,
      },
    };
  }

  return {
    props: {},
  };
};

/**
 * Layout Components are enabled in this Next.js project.
 * getLayout() called on a Next.js page will allow you to wrap the page in predefined reusable components— much like "getServerSideProps"
 * e.g. https://nextjs.org/docs/basic-features/layouts#per-page-layouts
 */
Index.getLayout = function getLayout(page: ReactElement) {
  return <AuthLandingLayout>{page}</AuthLandingLayout>;
};

type AuthComponentMap = {
  [key in TAuthSteps]: JSX.Element;
};
